import { all, put, takeEvery, select } from 'redux-saga/effects';
import {TOGGLE_PROFILE_VIEW} from "./MySettingsActions";
import DataProvider from "../../MyDataProvider";
import {isCaaApplProcessStatus} from "../../utils/applicationsHelpers";
import {MY_STORE_SETSELF_USERSTATE} from "../../../LogicV2/auth/MyAuthConstants";
import {isOperator} from "../MyRestConfig";
import {FETCH_START, FETCH_END, showNotification} from 'react-admin';
import {API_VERBS} from "../../MyApiVerbs";

//TODO optimize - call COUNT with needed statuses
function* checkApplicationsUserState({ type, show }) {

    //enter check only when showing profile view, not hiding
    if(!show) {
        return;
    }

    //enter check only if is operator
    const mySelf = yield select(
        (state) => state.mySelf
    );
    if(!isOperator(mySelf.roles)) {
        console.warn("checkApplicationsUserState saga. Is not operator!");
        return;
    }

    const dataProvider = DataProvider.getDataProvider();
    yield put({ type: FETCH_START });

    const dronesResource = 'drones';

    try {
        const dronesPromise = yield dataProvider(API_VERBS.GET_LIST, dronesResource, {pagination: {perPage: null}, sort: {field: "modified", order: "DESC"}});

        const applications = {
            data: [...dronesPromise.data, ],
        };

        let caaProcessing = false;
        for (const application of applications.data) {
            const status = application.status;
            if(isCaaApplProcessStatus(status)){
                caaProcessing = true;
                break;
            }
        }

        //set user state if can edit self
        yield put({
            type: MY_STORE_SETSELF_USERSTATE,
            newUserState: {
                canEditSelf: !caaProcessing
            }
        });

        yield put({ type: FETCH_END });
    }
    catch (e) {
        yield put({
            type: MY_STORE_SETSELF_USERSTATE,
            newUserState: {
                canEditSelf: undefined
            }
        });
        yield put(showNotification('error.UserStateCheck', 'warning',  { messageArgs: { error: e } }));
        yield put({ type: FETCH_END });
    }
}

export default function* () {
    yield all([
        takeEvery(TOGGLE_PROFILE_VIEW, checkApplicationsUserState),
    ]);
}
