//fixme notifications/error support

//in v1.1 we are replacing it with MyMissionField, so probably fullscreen will got outdated


import React, {createElement} from 'react';
//import PropTypes from 'prop-types';

import {connect} from 'react-redux'
import { Notification } from 'react-admin';
import {
    showNotification,
} from 'react-admin';


import { Authenticated } from 'react-admin';

import Button from '@material-ui/core/Button';

//import { translate } from 'ra-core';
import compose from 'recompose/compose';

import DataProvider from '../../dr_ra2/MyDataProvider'
import PubSub from "pubsub-js";

import {PubSubSignals} from '../../LogicV1Redux/constants/PubSubSignals'

import EditorMap from "../Components/Maps/OldEditorMap";
import {LiveMapMenu} from "../Components/_MyMenus";
import MissionPlanner from '../../LogicV1Redux/oldLogic/MyMissionPlanner';

//import {deserializeMissionData} from '../LogicV1Redux/oldLogic/MyMissionPlanner';
//import {missionFixJoinLeg2LegZone} from "../LogicV1Redux/oldLogic/MissionsListV2";
import {deserializeMissionData} from "../../LogicV1Redux/oldLogic/MissionsListV2";
import {API_VERBS} from "../../dr_ra2/MyApiVerbs";

const record2primitives = (record) => {

    const missionData = (record.legs)?deserializeMissionData({missionRecord:record }):false;
    console.log('MyMission', missionData);

    PubSub.publish(PubSubSignals.NEW_MISSION_LOADED, missionData);  //fixme redux?

    //huh fix name. as usually later
    const editables = (missionData && missionData.primitives)
        ? missionData.primitives : [];

    const primitives = (missionData && missionData.warnings)
        ? missionData.warnings.map( (warning) => warning.primitive) : [];

    //console.log('warnings primitives', primitives);

    return {editables, primitives};
};

class FSMissionEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { editables: [], primitives: [] };
        this.loadMission = this.loadMission.bind(this);
        this.saveMission = this.saveMission.bind(this);

    }

    //fixme to action like conflict resolver
    loadMission() {
        const dataProvider = DataProvider.getDataProvider();
        dataProvider(API_VERBS.GET_ONE, 'missions', { id: this.props.match.params.modelId })
            .then((req) => {
                console.log(API_VERBS.GET_ONE, 'result', req );
                //showNotification('myroot.auth.phoneVerified');
                // push('/myaccount');
                const {editables, primitives} = record2primitives(req.data);
                this.setState({editables, primitives });
                //console.log('MyMission record', record);

                console.warn('================= fix centering/zoom ')
            })
            .catch((e) => {
                console.error(e);
                //showNotification('myroot.httperror', 'warning');
            });
    }

    saveMission(data) {
        data.authToken = this.props.myAuth.token;
        data.missionId = data.missionRecord.id;
        console.log('saveMission', data);

        //hmmm
        //copied from NewMissionPlanner, due to forced reload needed for demo

        //older notes
        //a) we need to clean legs, as saving new ones really changes things (we have id, etc)
        //b) for demo we need fake await, since there is no info about ended intersect calc
        //c) for now failed save (incl failed save legs) is not supported...

        MissionPlanner.requestSave(data);
        //fake await.. damn..

        setTimeout(() => {

            this.loadMission();
        }, 3000);
    }

    componentWillMount() {
        console.error('FSMissionEditor... basically was working, but temporary leaving as (not used in 1.1)');
        //feeding from draw.js event directly, move to redux store...
        this.pubsubRequestSave = PubSub.subscribe(PubSubSignals.REQUEST_MISSION_SAVE, (msg, data) => {
            console.log('on requestMissionSave FSMissionEditor');
            //this.setState({primitive:data});
            this.saveMission(data)
        });
    }

    componentWillUnmount() {

        PubSub.unsubscribe(this.pubsubRequestSave);
    }

    componentDidMount() {
        console.log('FSMissionEditor', this.props);
        console.log('FSMissionEditor', this.state);
        //put(showNotification('myroot.httperror', 'warning', {autoHideDuration: 10000}));
        //hmm what about parallel editing...

        //fixme

        // if (this.props.location.record) {
        //     console.log('fixme record passed')
        // } else {
        //
        // }

        this.loadMission();
    }

    render() {
        const { width, height } = this.props.size;
        const { location, notification, showNotification } = this.props;

        const {editables, primitives} = this.state;
        const missionId = this.props.match.params.modelId;

        //console.log('fsMissionEditor', this.state);
        return (
            <Authenticated authParams={{ foo: 'bar' }} location={location}>

            <div style={{position:"absolute", top:0, bottom:0, left:0, right:0}}>
                <div >
                    <EditorMap
                        height={height}
                        width={width}
                        primitives={primitives}
                        editables={editables}
                        autofocus={editables}
                        menuStore={{missionPlanner:true, missionId}}
                        menuComponent={LiveMapMenu}
                    />
                </div>
                <div style={{position:"absolute", top:0, height:10, right:0}}>My size is {width} px x {height}px</div>

                <div style={{position:"absolute", top:100, height:10, right:0}}>
                    <Button variant="outlined" color="primary"
                        //className={classes.button}
                            onClick={() => { console.log('onClick');showNotification('clicked!', 'info'); }}
                    >
                        ClickMe
                    </Button>
                </div>

                <div style={{position:"absolute", bottom:0, left:0}}>
                    {createElement(notification)}
                </div>
            </div>
            </Authenticated>

        )
    }
}


FSMissionEditor.defaultProps = {
    //appBar: AppBar,
    //menu: Menu,
    notification: Notification,
};


const mapStateToProps = state => {
    //console.log('FSZoneEditor mapStateToProps', state);
    return {
        isLoading: state.admin.loading > 0,
        myAuth: state.myAuth,
    }
};
//
const enhance = compose(
    //translate,
    //muiThemeable(), // force redraw on theme change
    connect(
        mapStateToProps,
        {
            showNotification,
            //userLogout: userLogoutAction,
        }
    )
);


export default enhance(FSMissionEditor);

//export default sizeMe({ monitorHeight: true })(enhance(FSMissionEditor));

//export default sizeMe({ monitorHeight: true })(FSMissionEditor);
