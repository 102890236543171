import React from 'react';
import PostIcon from '@material-ui/icons/Book';
import UserIcon from '@material-ui/icons/Group';

import AccountBalance from '@material-ui/icons/AccountBalance';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import PersonOutline from '@material-ui/icons/PersonOutline';
import Widgets from '@material-ui/icons/Widgets';
import ReportProblem from '@material-ui/icons/ReportProblem';
import FindInPage from '@material-ui/icons/FindInPage';
import Security from '@material-ui/icons/Security';
import Games from '@material-ui/icons/Games';
import Send from '@material-ui/icons/Send';
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';

import { Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

import { DronesList, DronesCreate, DronesEdit, DronesShow, DronesArchiveList } from './components/resources/Drones';
import {CertificatesList, CertificatesCreate, CertificatesEdit, CertificatesShow, CertificatesArchiveList} from "./components/resources/Certificates";

import {MissionsCreate, MissionsEdit, MissionsList, MissionsShow} from "./components/resources/Missions";

import { ZonesShow} from './components/resources/Zones';

import { adminSaga } from 'react-admin';

//import restClientRouter from 'aor-rest-client-router';

import jsonRestClient from 'aor-json-rest-client';

import Dashboard from './components/DashboardOriExt';

import Admin from './components/MyAdmin'
//import {Admin} from 'react-admin'
import MyNewLoginPage from './components/auth/MyNewLoginPage';
import MyNewLogoutButton from './components/auth/MyNewLogoutButton';

import i18nProvider from "./i18n/Myi18nProvider";
import authProvider from './MyAuthProvider';
import customOpRoutes from './logic/CustomOpRoutes';
//import customReducers from './logic/CustomReducers';

import MyRestClient from './MyRestClient';
import MyDataProvider from './MyDataProvider';

import MyExtraAuthSaga from '../LogicV2/auth/MyExtraAuthSaga';

import {canRead} from './logic/MyRestConfig';
import {operatorUrls} from './logic/MyRestOperatorUrls';

import data from './data.json';
import menu from "./components/MyMenu";
import customReducers from "./logic/CustomReducers";
import Configs, {defaultLocale} from "../Configs";
import {
    MissionsV2Sagas,
    TickSagas,
    ZonesV2Sagas,
    ZoneTypesV2Sagas,
    AppStateSagas,
    MapUxConfigSagas,
    LiveAdsbExchangeSagas,

    RtdmCreotechTicksSagas,
    RtdmExchangeTicksSagas,
    RtdmExchangeAircraftsSagas,
    RtdmSoapLprUnitsTicksSagas,
    LocaleSagas,
    RehydrateSagas,
} from '../LogicV1Redux/sagas'

import {AppTitle} from "../LogicV1Redux/constants/AppTitleEnums";
import ApplicationsSagas from "../LogicV1Redux/sagas/ApplicationsSagas";
import MySettingsSagas from "./logic/settings/MySettingsSagas";
import {NotamsList, NotamsShow} from "./components/resources/Notams";

const noAccessPage = '/login'; //?? from code

const myUrls = {
    apiBase: Configs.urls.apiBaseV2,
    noAccessPage,
    defaultREST:operatorUrls,
};
//dang. have to be const, not object created in lamba on each state update.. at least now (static config)
export const AppConfig = {
    appMode:Configs.Apps.DR2_OP.type,
    app: Configs.Apps.DR2_OP,
    builtOn:Configs.builtOn,
    devBuild:Configs.devBuild,
};

//console.log(myUrls);

const dataProvider = MyDataProvider.init({
    rules: [
        ['certTypes','jsonSrv'],
        //['certs','jsonSrv'],
        ['insuranceTypes','jsonSrv'],
        ['insurances','jsonSrv'],
        ['posts','jsonServerProvider'],
        ['users','jsonServerProvider'],

        ['*','loopBackSrv']
    ],
    services: {
        jsonServerProvider: jsonServerProvider('http://jsonplaceholder.typicode.com'),
        loopBackSrv: MyRestClient(myUrls),
        jsonSrv: jsonRestClient(data)
    }
});

const testPermissions = (permissions) => {
    console.log('testPermissions',permissions);
    return null;
};

const customSagas = [
    adminSaga(dataProvider, authProvider, i18nProvider),
    MyExtraAuthSaga(dataProvider, authProvider),
    MissionsV2Sagas, //mission save here
    ZonesV2Sagas,
    ZoneTypesV2Sagas,
    AppStateSagas,

    MapUxConfigSagas,
    TickSagas,
    LiveAdsbExchangeSagas,

    RtdmCreotechTicksSagas,
    RtdmExchangeTicksSagas,
    RtdmExchangeAircraftsSagas,
    RtdmSoapLprUnitsTicksSagas,
    ApplicationsSagas,
    MySettingsSagas,
    LocaleSagas,
    RehydrateSagas,
];

//permissions -> hardcoded support for operator, hmmm...

export const App = () => (

        <Admin
            dataProvider={dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={MyNewLoginPage}
            logoutButton={MyNewLogoutButton}
            customRoutes={customOpRoutes}
            customSagas={customSagas}
            customReducers={customReducers}
            i18nProvider={i18nProvider}
            locale={defaultLocale}
            title = {AppTitle.CORE}
            menu = {menu}
            configs = {AppConfig}

        >
            {permissions => [
                testPermissions(permissions),

                <Resource name="zonesAll" //used by map!
                          show={ZonesShow}
                />,

                <Resource name="operators"/>,

                <Resource name="missions"
                          options={{label: 'resources.missions.menu'}}
                          list={MissionsList}
                          edit={MissionsEdit}
                          show={MissionsShow}

                          create={MissionsCreate}
                          icon={FlightTakeoff}
                />,

                <Resource name="drones"
                    options={{ label: 'resources.drones.menu', hideInMenu: false  }}
                    list={DronesList}
                    edit={DronesEdit}
                    create={DronesCreate}
                    icon={Send}
                />,

                <Resource name="dronesArchive"
                          options={{ hideInMenu: true  }}
                          list={DronesArchiveList}
                          show={DronesShow}
                />,
                <Resource name="droneModels"
                          // options={{label: 'resources.droneModels.menu'}}
                          // list={DroneModelsList}
                          // show={DroneModelsShow}
                          // icon={Games}

                />,

                <Resource name="containers"
                // list={ContainersList}
                // edit={ContainersEdit}
                // create={ContainersCreate}
                // remove={Delete}
                />,

                <Resource name="notams"
                          // options={{label: 'resources.notams.menu'}}
                          // list={canRead('notams', permissions) ? NotamsList : null}
                          // show={canRead('notams', permissions) ? NotamsShow : null}
                          // create={null}
                          // icon={Games}
                />,

            ]}

        </Admin>

);
