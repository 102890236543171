
// in src/customRoutes.js
import React from 'react';
import { Route } from 'react-router-dom';
import MyLoginPage from '../components/auth/MyNewLoginPage';
import LiveMap from '../../gl_map/old2review/FullScreenLiveMap';
//import ZoneEditor from '../../gl_map/FullScreenZoneEditor'
import MissionEditor from '../../gl_map/old2review/FullScrenMissionEditor'
//import {MyZoneConflictsResolver} from '../components/resources/MyZoneConflictsResolver'

import {OperatorRodoPage, OperatorHelpPage, OperatorFaqPage, DisclaimerPage} from '../../docs';

//to test
//https://github.com/ReactTraining/react-router/issues/4056
export const Refresh = ({ path = '/' }) => (
    <Route
        path={path}
        component={({ history, location, match }) => {
            history.replace({
                ...location,
                pathname:location.pathname.substring(match.path.length)
            });
            return null;
        }}
    />

);

//import MapWrapper from "../display_app/SimpleMapWrapper";
//login related stuff somehow skips nolaout
export default [
    <Route path="/Xlogin" component={MyLoginPage} noLayout />,
    <Route path="/Xregister" component={MyLoginPage} noLayout />,
    <Route path="/Xreset-pass-rq" component={MyLoginPage} noLayout />,
    <Route path="/map" component={LiveMap} noLayout />,
    <Route path="/docs/faq" component={OperatorFaqPage} />,
    <Route path="/docs/help" component={OperatorHelpPage} />,
    <Route path="/docs/rodo" component={OperatorRodoPage} />,
    <Route path="/docs/disclaimer" component={DisclaimerPage} />,
    //{/*'old' experimental routes: */},
    //<Route path="/mission-editor/:modelId" component={MissionEditor} noLayout />,

];
{/*<Route path="/refresh" component={Refresh} />*/}

{/*<Route exact path="/bar" component={Bar} />,*/}
{/*<Route exact path="/baz" component={Foo} noLayout />,*/}
